$smallScreen: 616px;

.card {
  min-height: initial;
}

.cardInfo {
  width: 100%;
  height: 100%;
  padding: 22px 24px 0;
  @media (max-width: $smallScreen) {
    padding: 8px;
  }
}

.title {
  width: 89%;
  height: 25px;
  @media (max-width: $smallScreen) {
    width: 100%;
    height: 15px;
  }
}

.text {
  margin-top: 12px;
  @media (max-width: $smallScreen) {
    display: none;
  }
}

.title2 {
  width: 120px;
  height: 20px;
  margin-top: 22px;
  @media (max-width: $smallScreen) {
    width: 69%;
    height: 15px;
    margin-top: 10px;
  }
}

.title3 {
  width: 120px;
  height: 20px;
  margin-top: 22px;
  @media (max-width: $smallScreen) {
    width: 62%;
    height: 15px;
    margin-top: 10px;
  }
}

.boxes1,
.boxes2 {
  display: flex;
  column-gap: 20px;
  width: 100%;
  @media (max-width: $smallScreen) {
    column-gap: 10px;
    display: block;
  }
}

.boxes1 {
  margin-top: 25px;
  @media (max-width: $smallScreen) {
    margin-top: 35px;
  }
}

.box {
  flex-grow: 1;
  height: 50px;
  border: 1px solid #dedede;
  display: grid;
  padding-left: 25px;
  padding-right: 25px;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: $smallScreen) {
    margin-bottom: 10px;
    &:last-child {
      display: none;
    }
  }
}

.boxSkeleton {
  width: 100%;
  height: 15px;
  @media (max-width: $smallScreen) {
    width: 60px;
    height: 15px;
    justify-self: center;
  }
}
