@font-face {
  font-family: 'Open Sans';
  src:
    local('Open Sans Regular'),
    url(../fonts/open-sans/OpenSans-Regular.woff2) format('woff2'),
    url(../fonts/open-sans/OpenSans-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans SemiBold';
  src:
    local('Open Sans SemiBold'),
    url(../fonts/open-sans/OpenSans-SemiBold.woff2) format('woff2'),
    url(../fonts/open-sans/OpenSans-SemiBold.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans Bold';
  src:
    local('Open Sans Bold'),
    url(../fonts/open-sans/OpenSans-Bold.woff2) format('woff2'),
    url(../fonts/open-sans/OpenSans-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans Italic';
  src:
    local('Open Sans Italic'),
    url(../fonts/open-sans/OpenSans-Italic.woff2) format('woff2'),
    url(../fonts/open-sans/OpenSans-Italic.woff) format('woff');
}

@font-face {
  font-family: 'Cormorant Garamond';
  src:
    local('Cormorant Garamond Regular'),
    url(../fonts/cormorant_garamond/CormorantGaramond-Regular.woff2) format('woff2'),
    url(../fonts/cormorant_garamond/CormorantGaramond-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Cormorant Garamond SemiBold';
  src:
    local('Cormorant Garamond SemiBold'),
    url(../fonts/cormorant_garamond/CormorantGaramond-SemiBold.woff2) format('woff2'),
    url(../fonts/cormorant_garamond/CormorantGaramond-SemiBold.woff) format('woff');
}

@font-face {
  font-family: 'Cormorant Garamond SemiBoldItalic';
  src:
    local('Cormorant Garamond SemiBoldItalic'),
    url(../fonts/cormorant_garamond/CormorantGaramond-SemiBoldItalic.woff2) format('woff2'),
    url(../fonts/cormorant_garamond/CormorantGaramond-SemiBoldItalic.woff) format('woff');
}

@font-face {
  font-family: 'Cormorant Garamond Bold';
  src:
    local('Cormorant Garamond Bold'),
    url(../fonts/cormorant_garamond/CormorantGaramond-Bold.woff2) format('woff2'),
    url(../fonts/cormorant_garamond/CormorantGaramond-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Palatino Linotype';
  src:
    local('Palatino Linotype'),
    url(../fonts/Palatino.woff2) format('woff2'),
    url(../fonts/Palatino.woff) format('woff');
}

@font-face {
  font-family: 'Palatino';
  src:
    local('Palatino'),
    url(../fonts/Palatino.woff2) format('woff2'),
    url(../fonts/Palatino.woff) format('woff');
}
