.skeleton {
  width: 100%;
  height: 50px;
  position: relative;
  // box-shadow: 0 2px 10px 0  rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background-color: #f1f1f1;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 80%;
    background: linear-gradient(to right, transparent 0%, #fff 50%, transparent 100%);
    animation: load 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

.light {
  background-color: #f1f1f1;
}

.dark {
  background-color: #dedede;
}

@keyframes load {
  from {
    left: -150px;
  }

  to {
    left: 100%;
  }
}
