@import '../../../styles/vars';

$smallScreen: 616px;
$mobileScreen: 460px;

.card {
  min-width: 373px;
  width: 30%;
  min-height: 648px;
  margin-bottom: 40px;
  border: 1px solid #e3e3e3;
  &:hover {
    border: 1px solid #b3b3b3;
  }
  // flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    width: 48%;
  }

  @media (max-width: 1032px) {
    // width: 47%;
  }

  @media (max-width: 818px) {
    min-width: 280px;
    min-height: 713px;
    margin-bottom: 24px;
  }

  @media (max-width: $smallScreen) {
    width: 48.5%;
    min-width: 162px;
    min-height: 249px;
    margin-bottom: 11px;
  }
  @media (max-width: 366px) {
    width: 100%;
  }
}

.cardImgBox {
  position: relative;
  min-height: 229px;
  overflow: hidden;
  background-color: #efefef;

  @media (max-width: $smallScreen) {
    min-height: 204px;
  }
  @media (max-width: $mobileScreen) {
    min-height: 99px;
  }
}

.cardImg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  //width: 100%;
  //height: 229px;
  //min-height: 229px;
  //object-fit: cover;
  //
  //@media (max-width: $smallScreen) {
  //  width: 100%;
  //  height: 204px;
  //}
  //
}

.cardImgButtons {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.cardImgButton {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 2px 16px;
  flex: 1;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  outline: none !important;

  &:first-child {
    padding-left: 15px;

    &[data-hovered='false'] {
      span {
        background-color: #c3a64e;
      }
    }
  }

  &:last-child {
    padding-right: 15px;
  }

  &:only-child {
    > span {
      display: none;
    }
  }

  > span {
    width: 100%;
    height: 4px;
    background-color: #fff;
    transition: 0.3s;
  }

  &:hover {
    span {
      background-color: #c3a64e;
    }
  }
}

.cardImgSlider {
  height: 100%;

  [class*='swiper-pagination-bullets'] {
    display: flex !important;
    position: absolute !important;
    bottom: 16px;
    left: 15px;
    right: 15px;
    z-index: 5;
    background-color: transparent !important;
    cursor: default !important;

    &:before {
      display: none;
    }
  }

  [class*='swiper-pagination-bullet'] {
    flex: 1;
    position: relative;
    height: 4px;
    margin-right: 4px;
    background-color: #fff;
    transition: 0.3s;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &:last-child {
      margin-right: 0;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: -10px;
      bottom: -10px;
    }
  }

  [class*='swiper-pagination-bullet-active'] {
    background-color: #c3a64e;

    &:only-child {
      display: none;
    }
  }
}

.cardImgSlide {
  position: relative;
  flex-shrink: 0;
  height: 100%;

  > img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;

    @media (max-width: 639px) {
      pointer-events: none;
    }
  }
}

.cardInfo {
  width: 100%;
  height: 100%;
  padding: 20px 24px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: $smallScreen) {
    padding: 8px;
  }
}

.title {
  display: block;
  cursor: pointer;
  margin-bottom: 10px;
  font-family: $primary-700-font;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  color: #1a1a1a;

  @media (max-width: $smallScreen) {
    font-size: 22px;
    line-height: 22px;
  }
  @media (max-width: $mobileScreen) {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 8px;
  }
}

.cardInfo {
  font-family: $content-400-font;
  font-style: normal;
}

.tel {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #1a1a1a;
  @media (max-width: $smallScreen) {
    font-size: 14px;
    line-height: 16px;
  }
  @media (max-width: $mobileScreen) {
    font-size: 12px;
    line-height: 12px;
  }
}

.subTitle {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #1a1a1a;
  @media (max-width: $smallScreen) {
    font-size: 16px;
    line-height: 16px;
  }
  @media (max-width: $mobileScreen) {
    font-size: 12px;
    line-height: 12px;
    color: #373737;
    margin-top: 12px;
    margin-bottom: 8px;
  }
}

.metroName {
  margin-right: 14px;
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #626262;
  cursor: pointer;
  @media (max-width: $smallScreen) {
    font-size: 14px;
    line-height: 22px;
  }
  @media (max-width: $mobileScreen) {
    font-size: 8px;
    line-height: 8px;
    margin-right: 7px;
  }
}

.hourItem {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #626262;

  &:not(:first-child) {
    margin-top: 10px;
  }
  @media (max-width: $smallScreen) {
    font-size: 14px;
    line-height: 16px;
  }
  @media (max-width: $mobileScreen) {
    font-size: 10px;
    line-height: 10px;
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
}

.infoLinkExtended,
.infoLinkEven,
.infoLinkOdd {
  cursor: pointer;
  margin-bottom: 16px;
  width: 152px;
  height: 44px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  color: #1a1a1a;

  & img {
    width: 21px;
    height: 21px;
    margin-right: 8px;
  }

  & p {
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: #1a1a1a;
    text-align: center;
  }

  @media (max-width: $smallScreen) {
    // width: 139.5px;
    width: 47.5%;
  }
}

.infoLinkOdd:last-child {
  width: 100%;
}

.infoLinkExtended {
  width: 100%;
}

.infoLinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 12px;
  column-gap: 16px;
}

.infoLinksSmall {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 4px;
  column-gap: 8px;
  @media (max-width: $smallScreen) {
    flex-wrap: wrap;
  }
}

.infoLinkEvenSmall {
  margin-top: 8px;
  width: 49%;
  height: 40px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  color: #1a1a1a;

  & img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  & p {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #1a1a1a;
    text-align: center;
  }

  @media (max-width: $smallScreen) {
    width: 100%;
    height: 32px;
    & img {
      width: 14px;
      height: 14px;
    }
    & p {
      font-size: 12px;
      line-height: 12px;
    }
  }
}

.photoPlaceholderDiv {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photoPlaceholder {
  width: 45px;
  height: 45px;
  border: 5px solid #dcdcdc;
  border-radius: 50%;
  border-top: 5px solid #626262;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
