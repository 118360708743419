$smallScreen: 616px;
$mobileScreen: 430px;

.search {
  width: 100%;
  height: 50px;
  border: 1px solid #dedede;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  margin-bottom: 24px;
  @media (max-width: $mobileScreen) {
    margin-bottom: 16px;
  }
}

.map {
  height: 879px;
  position: relative;
  margin-top: 30px;

  @media (max-width: $smallScreen) {
    height: 400px;
    margin-bottom: 50px;
    margin-top: 0;
    margin-left: 16px;
    margin-right: 16px;
  }
}

.header {
  margin: 28.5px 0;

  @media (max-width: 770px) {
    margin: 20.5px 0;
  }
}

.navpanel {
  margin-top: 49px;
  @media only screen and (max-width: 767px) {
    margin-top: 41px;
  }
}
