.navPanel {
  margin-top: 49px;
}

.header1 {
  width: 140px;
  padding-bottom: 20px;
  @media screen and (max-width: 767px) {
    width: 57.97%;
    padding-bottom: 10px;
  }
}

.header2 {
  padding-top: 35px;
  padding-bottom: 20px;
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.dropdown {
  width: 100%;
  height: 50px;
  border: 1px solid #dedede;
  display: flex;
  padding-left: 20px;
  padding-right: 40px;
  align-items: center;
  margin-bottom: 24px;

  @media screen and (max-width: 767px) {
    margin-bottom: 0;
    padding-right: 20px;
    border: none;
    width: 55%;
  }
}

.sections {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 23px;
  padding-bottom: 23px;
  border: 1px solid #e3e3e3;

  &:not(:last-child) {
    border-bottom: none;
  }
}

.sectionsItem {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    padding-bottom: 20px;
  }
}

.sectionLine {
  width: 100%;
  padding-left: 15px;
}

.sectionHeader {
  width: 100%;
  padding-right: 20px;
  padding-bottom: 30px;
}

.sectionHeader2 {
  width: 100%;
  padding-right: 20px;
}

.beerItems {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  column-gap: 2.5%;
  width: 100%;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    column-gap: 6px;
    column-gap: 2%;
  }
  @media screen and (max-width: 374px) {
    column-gap: 4px;
    column-gap: 1%;
  }
}

.filters {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.filter {
  width: 220px;
  height: 50px;
  border: 1px solid #dedede;
  display: flex;
  padding-left: 20px;
  padding-right: 60px;
  align-items: center;
  margin-bottom: 16px;
  margin-right: 20px;
}

.searchDiv {
  width: 100%;
  display: flex;
}

.search {
  width: 78%;
  height: 50px;
  border: 1px solid #dedede;
  display: flex;
  padding-left: 20px;
  padding-right: 60px;
  align-items: center;
  margin-right: 2%;
}

.sort {
  width: 20%;
  height: 50px;
  border: 1px solid #dedede;
  display: flex;
  padding-left: 20px;
  padding-right: 60px;
  align-items: center;
}

.mobHeader {
  margin-top: 33px;
}

.mobTypes {
  margin-top: 28px;
  display: flex;
  column-gap: 6.5%;
  width: 100%;
}

.mobFilter,
.mobFilter2 {
  width: 100%;
  height: 50px;
  border: 1px solid #dedede;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  margin-top: 25px;
}

.mobFilter2 {
  padding-left: 93px;
  margin-top: 16px;
}

.mobParams {
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
}

.mobParam1 {
  flex-grow: 1;
  height: 50px;
  border: 1px solid #dedede;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
}

.mobParam2 {
  height: 50px;
  width: 50px;
  border: 1px solid #dedede;
  margin-left: 16px;
}
