@import '../../../styles/vars';

.beerSearch {
  margin-top: 24px;
  margin-bottom: 92px;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.beerSearchLeft {
  width: 228px;
  min-width: 228px;
  margin-right: 24px;
  // @media screen and (max-width: 767px) {
  //   width: 100%
  // }
}

.beerSearchRight {
  width: 100%;
}
.dropdown {
  margin-top: 16px;
  margin-bottom: 24px;
  @media screen and (max-width: 767px) {
    margin-top: 0;
    margin-bottom: 16px;
    flex-grow: 1;
  }
}

// --------------------- поисковая строка ---------------------

.serachDiv {
  display: flex;
  width: 100%;
  @media screen and (max-width: 767px) {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.searchCloseDiv,
.searchIconDiv {
  height: 48px;
  padding-right: 16px;
  border: 1px solid #c8c8c8;
  border-left: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchIcon {
  height: 16.5px;
  width: 16.5px;
}
.closeIcon {
  cursor: pointer;
  height: 11.666666030883789px;
  width: 11.666666030883789px;
}

.searchInput {
  flex-grow: 1;
  width: 100%;
  padding: 16px;
  height: 48px;
  border: 1px solid #c8c8c8;
  border-radius: 0;
  border-right: none;

  font-family: $content-400-font;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #828282;
  &:focus {
    outline: none;
  }
}
// --------------------- поисковая строка (end) ---------------------

.headerBeer,
.plugHeader {
  margin: 24px 0;
  font-family: $primary-700-font;
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 34px;
  color: #000000;
  @media screen and (max-width: 767px) {
    margin: 24px 0 7px;
    font-size: 32px;
    line-height: 32px;
  }
}

.plugHeader {
  min-height: 20vh;
  @media screen and (max-width: 767px) {
    margin-top: 24px;
    margin-bottom: 92px;
  }
}

.disabled {
  display: none;
}

.popularSections {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.popularSection {
  cursor: pointer;
  margin-right: 16px;
  margin-bottom: 16px;
  height: 40px;
  width: auto;
  padding-top: 10px;
  padding-left: 14px;
  padding-right: 14px;
  font-family: $content-400-font;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #1a1a1a;
  border: 1px solid #e3e3e3;
  white-space: nowrap;
}

.closeSectionIcon {
  cursor: pointer;
  & img {
    height: 8.75px;
    width: 8.75px;
  }
}

.beerItems {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2.5%;
  width: 100%;
  // justify-content: space-between;
  @media screen and (max-width: 1027px) {
    // column-gap: 12px;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    column-gap: 2%;
  }
  @media screen and (max-width: 374px) {
    column-gap: 1%;
  }
  a {
    cursor: default;
  }
}

.beerItemsTriple {
  column-gap: 24px;
  @media screen and (max-width: 777px) {
    column-gap: 12px;
  }
  @media screen and (max-width: 767px) {
    column-gap: 2%;
  }
  @media screen and (max-width: 374px) {
    column-gap: 1%;
  }

  &::after {
    content: '';
    width: 384px;
  }
}

.sectionMore {
  cursor: pointer;
  height: 60px;
  width: 100%;
  border: 1px solid #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: $content-400-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: right;
  color: #1a1a1a;
  @media screen and (max-width: 767px) {
    margin-bottom: 40px;
  }
}

// --------------------------- мобайл дизайн ---------------------------

.menuSections {
  width: 100%;
  min-height: 52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 0;
  margin-bottom: 19px;
  position: relative;
  border: none;
}

.menuSectionsBox {
  width: 100%;
  min-height: 52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 16px 0;
  margin: 0 0 16px;
  position: relative;
  border: 1px solid #e3e3e3;
}

.menuSectionItem {
  font-family: $content-400-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  // margin-bottom: 26px;
  padding-left: 16px;

  // color: #1A1A1A;
  display: none;

  & p {
    // width: 90%
  }
}

.menuSectionItemActive {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $primary-700-font;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  // text-align: center;
  color: #1a1a1a;
  border: none;
  order: -1;
  width: 100%;
  text-align: center;
  padding-left: 0;
}

.iconDownDiv {
  padding: 0 10px;
}

.iconLeftDiv {
  padding-right: 18px;
  align-self: flex-start;
}

.iconLeft {
  position: absolute;
  // left: 8px;
  width: 24px;
  text-align: center;
  margin-top: 3px;
  cursor: pointer;
}

.filterDiv {
  display: flex;
}

.iconRight {
  position: absolute;
  right: 0px;
  width: 24px;
  text-align: center;
  margin-top: 3px;
  cursor: pointer;
}

.iconFilter {
  width: 48px;
  height: 48px;
  margin-left: 16px;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    margin-bottom: 16px;
  }
}

.filterModal {
  margin: 0;
  position: fixed;
  height: 100vh;
  width: 100vw;
  // display: none;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
  // overflow-y: scroll;
  background: #ffffff;
}

.modalHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $primary-700-font;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 28px;
  color: #1a1a1a;
  padding: 14px 22px 14px 16px;
  border-bottom: 1px solid #e3e3e3;
}

.modalHeadTitle {
  font-size: 1.5em;
}

.modalFilters {
  padding: 16px;
  margin-bottom: 16px;
  // height: auto;
  overflow-y: scroll;
  border-bottom: 1px solid #e3e3e3;
}

.modalShow {
  cursor: pointer;
  margin: 16px;
  // width: 100%;
  height: 48px;
  background: linear-gradient(0deg, #34455e, #34455e), #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  & p {
    font-family: $content-400-font;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
  }
}

.diabled {
  display: none;
}

.header1 {
  font-family: $primary-700-font;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  color: #1a1a1a;
}

.visible {
  margin: 0;
}
.bottleDiv {
  display: flex;
}

.bottleType,
.bottleTypeActive {
  padding: 12px 16px;
  flex-grow: 1;
  font-family: $content-400-font;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #939393;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
}

.bottleTypeActive {
  color: #1a1a1a;
  border-bottom: 1px solid #1a1a1a;
}
