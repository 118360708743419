$mobileScreen: 430px;

.wrap {
  padding-bottom: 130px;
  @media screen and (max-width: $mobileScreen) {
    padding-bottom: 44px;
  }
}

.beerCardThree {
  min-height: 290px;
  min-width: 384px;
  width: 29%;
  margin-bottom: 24px;
  border: 1px solid #e3e3e3;
  @media screen and (max-width: 1200px) {
    min-width: 360px;
  }
  @media screen and (max-width: 1160px) {
    width: 48%;
  }
  @media screen and (max-width: 767px) {
    width: 49%;
    min-width: 168px;
    margin-bottom: 8px;
    min-height: 100px;
  }
  @media (max-width: 371px) {
    width: 100%;
  }
}

.beerCardTwo {
  min-height: 290px;
  min-width: 360px;
  width: 48.75%;
  margin-bottom: 24px;
  border: 1px solid #e3e3e3;
  @media screen and (max-width: 1023px) {
    min-width: 462px;
  }
  @media screen and (max-width: 767px) {
    width: 49%;
    min-width: 168px;
    margin-bottom: 8px;
    min-height: 100px;
  }
  @media (max-width: 371px) {
    width: 100%;
  }
}

.card {
  cursor: default;

  width: 100%;
  height: 100%;
  display: flex;
}

.card:hover {
  border: 1px solid #34455e;
}

.imgDiv {
  min-width: 170px;
  max-width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #e3e3e3;
  position: relative;
  @media screen and (max-width: 710px) {
    min-width: 100px;
    max-width: 100px;
  }
  @media screen and (max-width: 560px) {
    min-width: 84px;
    max-width: 84px;
  }
  @media screen and (max-width: 460px) {
    min-width: 62px;
    max-width: 62px;
  }
  @media screen and (max-width: 371px) {
    min-width: 84px;
    max-width: 84px;
  }
}

.img {
  height: 288px;
  width: 100%;
  object-fit: contain;
  @media screen and (max-width: 710px) {
    height: 100px;
  }
}

.description {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    padding: 15px;
    justify-content: flex-start;
  }
}

.text {
  width: 100%;
  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
  }
}

.title {
  margin-bottom: 20px;
  height: 20px;
  width: 53.8%;
  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
    width: 100%;
    height: 15px;
  }
}

.type {
  width: 100%;
}

.prices {
  width: 100%;
}

.price {
  height: 20px;
  width: 53.8%;
  @media screen and (max-width: 767px) {
    height: 15px;
    width: 100%;
  }
}
