@import 'vars.scss';
@import 'fonts.scss';

*,
*:after,
*:before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  width: 100%;

  * {
    -webkit-font-smoothing: antialiased;
  }

  &.is-scroll-lock {
    @media (max-width: 767px) {
      width: 100%;
      overflow: hidden !important;
      position: fixed !important;
    }
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

button {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

button::-moz-focus-inner {
  border: 0;
}

ul {
  list-style-type: none;
}

a:active, /* активная/посещенная ссылка */
a:hover,  /* при наведении */
a {
  text-decoration: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  //min-height: 100%;
  overflow-x: hidden;
  max-width: 1200px;
  margin: auto;

  @media (min-width: 320px) and (max-width: 1200px) {
    margin: 0 16px;
  }
}

.wrapper-visible {
  overflow: visible;
}

.wrapper-blocked {
  overflow-y: hidden;
  height: 100vh;
}

.content-font {
  font-family: $content-400-font;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #1a1a1a;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.d-flex {
  display: flex;
}

.jc-center {
  justify-content: center;
}

.jc-space-between {
  justify-content: space-between;
}

ymaps {
  touch-action: auto;
}

//.ymaps-2-1-79-balloon_layout_panel {
//  right: -1px !important;
//  bottom: 0 !important;
//  left: auto !important;
//  top: -400px !important;
//  width: 309px;
//  // height: 400px !important;
//  // width: 309px !important;
//  & .ymaps-2-1-79-balloon__close {
//    display: none !important;
//  }
//  & .ymaps-2-1-79-balloon__content {
//    margin-right: 0 !important;
//    // & ymaps {
//    //   overflow-y: visible;
//    // }
//  }
//}

// [class*="ymaps-2"][class*="-ground-pane"] {
//   filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
//   /* Firefox 3.5+ */
//   -webkit-filter: grayscale(100%);
//   /* Chrome 19+ & Safari 6+ */
// }

.lambicclub-button {
  width: 100%;
  max-width: 380px;
  height: 60px;
  background-color: #34455e;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-family: $content-400-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff !important;
  &:hover {
    background-color: #27417d;
  }
}

.swiper-arrow {
  display: block;
  width: 50px;
  height: 25px;
  position: absolute;
  z-index: 5;
  top: 50%;
  margin-top: -12.5px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iMjUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMTNoMjQiIHN0cm9rZT0iZ3JheSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48cGF0aCBkPSJNMSAxM2gyNCIgc3Ryb2tlPSIjODI4MjgyIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjxwYXRoIGQ9Ik0yNSAxM2gyNG0wIDAtOS05bTkgOS05IDkiIHN0cm9rZT0iZ3JheSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48cGF0aCBkPSJNMjUgMTNoMjRtMCAwLTktOW05IDktOSA5IiBzdHJva2U9IiM4MjgyODIiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+);

  &.swiper-button-prev {
    left: 0;
    transform: scaleX(-1);
  }

  &.swiper-button-next {
    right: 0;
  }

  &.swiper-button-disabled {
    display: none;
  }

  @media screen and (max-width: 1380px) {
    // display: none;
  }
}

.custom-balloon {
  max-width: 268px;
  font-family: $content-400-font;
  font-size: 18px;
  line-height: 1.667em;
  box-sizing: border-box;
  color: #5f5f5f;

  * {
    box-sizing: border-box;
  }

  &__title {
    margin-bottom: 16px;
    font-family: $primary-700-font;
    font-size: 22px;
    line-height: 1.3em;
    color: #484848;
  }

  &__metro {
    margin-top: 16px;
    font-family: $primary-600-font;
    font-style: italic;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.2em;
    word-wrap: break-word;
  }

  &__btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 1px;
    border: 1px solid #1a1a1a;
    padding: 9px 14px 11px 14px;
    text-align: center;
    color: #1a1a1a;
    line-height: 1em;
  }
}

.foo {
  background-color: #fff;
}

.body-hidden {
  overflow: hidden;
  //overflow-x: hidden;
  //overflow-y: hidden;
}

.disabled {
  display: none;
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  @media (max-width: 767px) {
    overflow: hidden !important;
    position: fixed;

    #root {
      width: 100vw;
    }
  }
}

html.fslightbox-open {
  @media (max-width: 767px) {
    position: fixed;
    width: 100%;
  }
}

.fslightbox-container {
  background: rgba(0, 0, 0, 0.8) !important;
}

.fslightbox-nav {
  .fslightbox-toolbar-button {
    &:first-child {
      display: none;
    }
  }
}

// .fslightbox-source-inner {
.fslightbox-full-dimension.fslightbox-flex-centered {
  // > img {
  img {
    display: block !important;
    width: auto !important;
    height: auto !important;
    max-width: 80vw !important;
    max-height: 80vh !important;
    opacity: 1 !important;
  }
}

.fslightbox-slide-btn-container {
  .fslightbox-slide-btn {
    width: 46px;
    height: 24px;
    padding: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 46px 24px;
    background-color: transparent;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDYiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMTIuNWgyME0xIDEyLjVoMjBNMjEgMTIuNWgyNG0wIDAtOS05bTkgOS05IDlNMjEgMTIuNWgyNG0wIDAtOS05bTkgOS05IDkiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=);

    @media (max-width: 767px) {
      width: 20px;
      background-position: center right;
    }

    svg {
      display: none;
    }
  }

  &.fslightbox-slide-btn-previous-container {
    left: 10px;

    .fslightbox-slide-btn {
      transform: scaleX(-1);
    }
  }

  &.fslightbox-slide-btn-next-container {
    right: 10px;
  }
}

.fslightbox-fade-in-strong {
  animation: fslightbox-fade-in-strong 0.25s !important;
}

.fslightbox-fade-out-strong {
  animation: fslightbox-fade-out-strong 0.6s !important;
}

.fslightbox-slide-swiping-hoverer {
  pointer-events: none !important;
}

// все слайдеры
.swiper-wrapper {
  height: auto !important;
}

// swiper О нас на главной
.main-aboutSliderBox .swiper-wrapper,
.address-cardImgBox .swiper-wrapper {
  height: 100% !important;
  display: flex;
}

// swiper с авторским меню на главной

.main-regularSliderBox .swiper-wrapper {
  margin-bottom: 27px;
  height: auto;
  display: flex;
  @media screen and (min-width: 1380px) {
    margin-bottom: 0;
  }
}

.main-regularSliderBox .swiper-slide {
  flex-shrink: 0;
}

.main-regularSliderBox .swiper .swiper-scrollbar {
  // display: block !important;
  left: 0;
  right: 0;
  background-color: #e3e3e3;
  border-radius: 0;
  height: 8px;

  @media screen and (min-width: 1380px) {
    display: none !important;
  }
}

.main-regularSliderBox .swiper .swiper-scrollbar .swiper-scrollbar-drag {
  background-color: #34455e;
  border-radius: 0;
  width: 80px !important;
}

.main-skeleton-regularSliderBox .swiper .swiper-scrollbar .swiper-scrollbar-drag {
  background-color: #e3e3e3;
  border-radius: 0;
  width: 80px !important;
}

// меню ланч

.lunch-card-only,
.lunch-card2-only {
  width: 100%;
  // border: 1px solid #e3e3e3;
  background: rgba(246, 246, 246, 0.7);
  padding: 16px 20px;
  margin-bottom: 24px;
  @media (max-width: 835px) and (min-width: 767px) {
    padding: 14px;
  }
  @media (max-width: 600px) {
    padding: 14px;
  }
}

.lunch-card2-only {
  background: inherit;
  border: 1px solid #e3e3e3;
  max-width: 647px;
}

.lunch-cards {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  column-gap: 2%;
  max-width: 647px;
  @media (max-width: 767px) {
    max-width: 100%;
  }
}

.lunch-card,
.lunch-card2 {
  // border: 1px solid #e3e3e3;
  background: rgba(246, 246, 246, 0.7);
  padding: 14px 16px;
  margin-bottom: 16px;
  min-width: 200px;
  width: 32%;

  & div {
    padding: 0;
  }
  @media screen and (max-width: 909px) {
    width: 49%;
  }

  @media screen and (max-width: 908px) {
    // min-width: 240px;
    // width: 47%;
  }

  @media (max-width: 787px) {
    min-width: 160px;
  }
  @media (max-width: 366px) {
    width: 100%;
  }

  @media (max-width: 835px) and (min-width: 767px) {
    padding: 14px;
  }
  @media (max-width: 600px) {
    padding: 14px;
  }
}

.lunch-card2 {
  background: inherit;
  border: 1px solid #e3e3e3;
}

.lunch-card-name {
  font-family: $primary-400-font;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #1a1a1a;

  @media (max-width: 835px) and (min-width: 767px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.lunch-card-text {
  margin-top: 6px;
  font-family: $primary-400-font;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #828282;
  @media (max-width: 835px) and (min-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 18px;
  }
}

.lunch-card-price {
  margin-top: 8px;
  font-family: $primary-600-font;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #1a1a1a;
  @media (max-width: 835px) and (min-width: 767px) {
    font-size: 18px;
    line-height: 20px;
  }
  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 20px;
  }
}

.lunch-subtitle {
  margin-top: 16px;
  margin-bottom: 20px;
  font-family: $primary-700-font;
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 34px;
  color: #1a1a1a;

  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 22px;
  }
}

.lunch-text-bold {
  margin-bottom: 24px;
  font-family: $primary-700-font;
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 34px;
  color: #1a1a1a;
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 22px;
  }
}

.lunch-text-list {
  margin: 24px 0;
  font-family: $content-400-font;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  color: #1a1a1a;
}

.lunch-text-italic {
  font-family: $content-400-font;
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #1a1a1a;
}

.lazy-load-image-loaded {
  display: initial !important;
}
