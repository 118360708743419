// fonts
$primary-400-font: 'Cormorant Garamond', 'serif';
$primary-600-font: 'Cormorant Garamond SemiBold', 'serif';
$primary-italic-600-font: 'Cormorant Garamond SemiBoldItalic"', 'serif';
$primary-700-font: 'Cormorant Garamond Bold', 'serif';
$content-400-font: 'Open Sans', 'Verdana', 'sans-serif';
$content-600-font: 'Open Sans SemiBold', 'Verdana', 'sans-serif';
$content-700-font: 'Open Sans Bold', 'Verdana', 'sans-serif';
$content-italic-font: 'Open Sans Italic', 'Verdana', 'sans-serif';
$palatino-font: 'Palatino Linotype', 'serif';
$white: #ffffff;
$lambic-red: #bf4d28;
$lambic-red-hover: #b3411c;
$lambic-grey: #e3e3e3;
$lambic-black: #1a1a1a;

@mixin Nav-item() {
  font-family: $primary-400-font;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
}

@mixin Title-22() {
  font-family: $primary-700-font;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  color: #1a1a1a;
}

@mixin Title-26() {
  font-family: $primary-700-font;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 26px;
  color: #1a1a1a;
}

@mixin Title-30() {
  font-family: $primary-700-font;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  color: #1a1a1a;
}

@mixin Title-34() {
  font-family: $primary-700-font;
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 34px;
  color: #1a1a1a;
}

@mixin Text-small() {
  font-family: $content-400-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #1a1a1a;
}

@mixin Text-standart() {
  font-family: $content-400-font;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #373737;
}

@mixin Text-standart-18() {
  font-family: $content-400-font;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #1a1a1a;
}

@mixin mobile-large() {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin mobile() {
  @media screen and (max-width: 430px) {
    @content;
  }
}

@mixin msgModal {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &[data-show='true'] {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

@mixin msgModalBox {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 200px;
  padding: 40px 45px 40px 40px;
  background-color: #fff;
  font-family: $primary-700-font;
  font-size: 24px;
  z-index: 2;

  @media (max-width: 767px) {
    position: fixed;
    bottom: 0;
    width: 100% !important;
    border-radius: 16px 16px 0 0;
    padding: 14px 0;
    overflow: hidden;
  }
}

@mixin msgModalOverlay {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@mixin modalCloser {
  filter: none;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 40px;
  transition: 0.3s;
  cursor: pointer;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCAxNCI+PHBhdGggZmlsbD0iIzk3OTk5YiIgZD0ibTguNDEgNyA1LjMtNS4yOUExIDEgMCAxIDAgMTIuMjkuMjlMNyA1LjU5IDEuNzEuMjlBMSAxIDAgMCAwIC4yOSAxLjcxTDUuNTkgN2wtNS4zIDUuMjlhMSAxIDAgMCAwIDAgMS40MiAxIDEgMCAwIDAgMS40MiAwTDcgOC40MWw1LjI5IDUuM2ExIDEgMCAwIDAgMS40MiAwIDEgMSAwIDAgMCAwLTEuNDJaIi8+PC9zdmc+);

  @media (max-width: 767px) {
    display: none;
  }
}
