@import '../../styles/vars';

.modal {
  position: relative;
  width: 460px;
  height: auto;
  min-height: 200px;
  background-color: #fff;
  font-family: $primary-700-font;
  font-size: 24px;
  z-index: 99;

  @include mobile-large {
    position: fixed;
    bottom: 0;
    width: 100% !important;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
  }

  &:focus {
    outline: none;
  }
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  position: fixed;
  z-index: 98;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  height: 258px;
  object-fit: cover;
  @include mobile-large {
    height: 230px;
  }
}

.contentWithImage {
  padding: 40px;
  @include mobile-large {
    padding: 32px 17px 64px;
  }
}

.contentWithoutImage {
  padding: 80px 40px;
  @include mobile-large {
    padding: 65px 17px;
  }
}

.title {
  @include Title-26;
  font-size: 24px;
  line-height: 32.02px;
  text-align: center;
}

.text {
  margin-top: 24px;
  font-family: $content-400-font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20.02px;
  letter-spacing: 0.17px;
  color: #808080;
  text-align: center;
}

.link {
  margin-top: 32px;
  display: block;
  width: 100%;
  background-color: #1976d2;
  border-radius: 4px;
  font-family: $content-400-font;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.46px;
  color: $white;
  padding: 8px 22px;
  text-align: center;
}

.closeArea {
  position: absolute;
  top: 17px;
  right: 17px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(245, 245, 245, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.closeIcon {
  width: 14px;
  height: 14px;
  background-image: url('data:image/svg+xml,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="black" fill-opacity="0.54"/></svg>');
}
