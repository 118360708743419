@import '../../styles/vars';

$smallScreen: 616px;
$mobileScreen: 430px;

.header {
  font-family: $primary-700-font;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 34px;
  margin: 24px 0;

  @media (max-width: 770px) {
    margin: 16px 0;
  }
}

.serachDiv {
  display: flex;
  width: 100%;
}

.searchIconDiv {
  height: 48px;
  padding-right: 16px;
  border: 1px solid #c8c8c8;
  border-left: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchIcon {
  height: 16.5px;
  width: 16.5px;
}

.metroFilter {
  flex-grow: 1;
  margin-bottom: 24px;
  padding: 16px;
  height: 48px;
  border: 1px solid #c8c8c8;
  border-right: none;
  border-radius: 0;
  // background: url('../../../images/icon-search.svg') no-repeat;
  // background-position-x: right;
  // background-position-y: center;
  font-family: $content-400-font;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #828282;

  &:focus {
    outline: none;
  }

  @media (max-width: $mobileScreen) {
    margin-bottom: 16px;
  }
}

.metroStations {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
}

.metroStationNotFound,
.metroStation {
  margin-right: 16px;
  margin-bottom: 16px;
  // width: 163px;
  height: 40px;
  border: 1px solid #e3e3e3;
  text-align: center;
  padding: 10px 14px 12px;
  cursor: pointer;
  font-family: $content-400-font;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #1a1a1a;
}

.metroStationNotFound {
  cursor: default;
}

.addressCards {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  // justify-content: space-between;
  column-gap: 40px;
  @media (max-width: 1200px) {
    column-gap: 20px;
    column-gap: 4%;
  }
  @media (max-width: 1032px) {
    // column-gap: 6%;
  }
  @media (max-width: 616px) {
    column-gap: 12px;
    column-gap: 3%;
  }
  @media (max-width: 446px) {
    column-gap: 6px;
    column-gap: 3%;
  }
}

.addressesMap {
  height: 879px;
  position: relative;
  overflow: hidden;
  background-color: #efefef;
  margin-top: 30px;

  @media (max-width: $smallScreen) {
    height: 400px;
    margin-bottom: 50px;
    margin-top: 0;
  }
}

.loadBtn {
  margin-bottom: 75px;
  cursor: pointer;
  height: 60px;
  width: 100%;
  border: 1px solid #e3e3e3;
  font-family: $content-400-font;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #1a1a1a;
  text-align: center;

  @media (max-width: $smallScreen) {
    margin-top: 12px;
    margin-bottom: 40px;
  }
}

.loadBtnDeactive {
  display: none;
}

.addressBalloonDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-width: 288px;
}

.addressTitle {
  margin: 22px 32px 16px 22px;
  font-family: $primary-700-font;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  color: #1a1a1a;

  @media (max-width: $smallScreen) {
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
  }
}

.addressHours {
  margin-bottom: 6px;
  margin-right: 32px;
  margin-left: 22px;
  font-family: $content-400-font;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #373737;

  @media (max-width: $smallScreen) {
    margin-right: 0;
    margin-left: 0;
  }
}

.addressMetro {
  margin: 16px 32px 24px 22px;
  font-family: $primary-600-font;
  font-style: italic;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #373737;
  word-wrap: break-word;

  @media (max-width: $smallScreen) {
    margin-right: 0;
    margin-left: 0;
  }
}

.disabled {
  display: none;
}

.modal {
  position: fixed;
  // top: 50%;
  // left: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  // transform: translate(-50%, -50%);
  // border: 1px solid #ccc;
  background: #fff;
  // overflow: auto;
  outline: none;
  padding: 32px;
  // max-height: 72%;
  // overflow-y: hidden;
  width: 100%;
  z-index: 99;
  border-radius: 16px 16px 0 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 98;
}
